.DialogContent {
  min-width: 400px;
}

.customModalCloseIcon {
  display: none !important;
}

.customModalHeader {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 8px 0px;
  align-items: center;
  border-bottom: 1px solid #E5E7ED;
}

.customModalTitle {
  font-size: 16px;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  flex: 1 1;
  font-weight: bold;
}

.customModalRightMargin {
  margin-right: 4px;
}

.MuiDialog-paper {
  margin: 32px;
  position: relative;
  overflow-y: auto;
}

.MuiDialog-paperScrollPaper {
  max-height: 90vh !important
}

.customModalDialogFooter {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.customModalDialogError {
  color: #FF2A2A;
  margin-bottom: 8px;
  display: flex;
  width: 328px;
}

.customModalHeaderIcon {
  color: #1891F6;
  margin-right: 16px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background: linear-gradient(99.7deg, #15205E -19.43%, #0076F4 80.93%);
  box-Shadow: 0px 11px 12px -10px rgba(4, 105, 221, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 9px 24px !important;
  font-size: 1rem !important;
  line-height: 1.2rem !important;
  text-transform: none !important;
}
.MuiButton-containedPrimary:hover {
  background-color: #303f9f !important;
}
.MuiButton-containedPrimary:disabled {
  background: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.MuiDialog-paperWidthSm {
  max-width: 600px;
}
.MuiButton-outlinedPrimary:hover {
  background-color: #ffffff !important;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.23) !important
}
.MuiButton-outlinedPrimary {
  padding: 9px 24px !important;
  font-size: 1rem !important;
  line-height: 1.2rem !important;
  text-transform: none !important;
  color: #000 !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.tableWrapper {
  max-height: calc(100vh - 325px);
  overflow: auto;
}

.table {
  height: 100%;
}

.footerContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  color: #656565;
}
.confirmContainer {
  display: flex;
  flex-direction: column;
}

.confirmContent {
  padding: 10px 20px 10px 20px;
  display: inherit;
  justify-content: center;
  text-align: center;
}
.confirmContentLeftAlign {
  display: inherit;
  justify-content: left;
  text-align: left;
}
.confirmContentCenterAlign {
  display: inherit;
  justify-content: center;
  text-align: center;
  word-break: break-word;
}

.footerSelect {
  width: 50px;
  margin: 0 0.5rem;
  height: 20px;
  background-color: transparent;
  color: black;
  font-weight: bold;
  border: none;
  outline: none;
}

#CustomGridPagination li:first-child button::after {
  content: "Prev";
  margin-left: 16px;
  margin-right: 8px;
}

#CustomGridPagination li:last-child button::before {
  content: "Next";
  margin-left: 8px;
  margin-right: 16px;
}

.footerPagerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  border: 0px !important;
  padding-top: 24px;
  position: sticky;
  top: 0px;
  left: 0px;
  background: #F0F0F4 !important;
}

.footerFirstContainer {
  width: 30%;
  display: flex;
}

.footerItemContainer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.footerLastItemContainer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}


.messageContainerDefaultHeight {
  min-height: 300px;
}

.tableWrapper .rowHighlight {
  background-color: rgba(25, 118, 210, 0.12) !important;
}

.defaultCursor {
  cursor: default !important;
}
.divDeleteFooter {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.customModalDeleteIcon {
  color: #FF2A2A;
  margin-right: 3.33px;
  width: 16.33px !important;
  height: 12px !important;
  cursor: pointer;

}
.customModalDeleteHeader {
  width: 123px;
  height: 14px;
  font-family: (Roboto, 'Arial', 'Verdana', 'Tahoma');
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FF2A2A;
  cursor: default;
}